import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Col, Card } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

const Sidebar = ({ children }) => {
  const [show, setShow] = useState(false, true)

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })
  return (
    <>
      <Col
        xs={{ span: 12, order: "last" }}
        md={{ span: 4, order: "first" }}
        className="sidebar"
      >
        <Link className="link-unstyled" to="/work/snc">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Small Nut Collective</p>
              <p>Two Matts, one dream</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/robin">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Robin Hood's Car</p>
              <p>Big time treasure for a small time criminal</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/outside">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Go Outside</p>
              <p>Tumultous inner life</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/strangers">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Strangers May Kiss</p>
              <p>
                Two teenage couples grapple with love, change, and friendship
              </p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/utt">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Until the Tide Turns</p>
              <p>
                A young man reconciles with familial unrest and breaking the law
              </p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/mcd">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">McDonald's Man</p>
              <p>An absurdist mockumentary of epic proportions</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/kf">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">KF</p>
              <p>An investigation of memory and its fallability</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/c63">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Commodore 63</p>
              <p>Solemn echoes of teenage angst and unrest</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/twins">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">TWINS</p>
              <p>Sibling rivalry reaches a breaking point</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/voyage">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Voyage in the Blue</p>
              <p>Three bad days: a series of life defining moments</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/henry">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Henry Vaughn!</p>
              <p>Name says it all</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/inventory">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Inventory</p>
              <p>Possessions and possessions and possessions</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/hyype">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">HYYPE</p>
              <p>Streetwear culture goes too far</p>
            </Card.Body>
          </Card>
        </Link>
        <Link className="link-unstyled" to="/work/sneakin">
          <Card className="mb-2 text-white bg-dark">
            <Card.Body>
              <p className="font-weight-bold">Sneakin'</p>
              <p>Early adolescent mischief and its ramifications</p>
            </Card.Body>
          </Card>
        </Link>
      </Col>
      <Col
        xs={{ span: 12, order: "second" }}
        md={{ span: 8, order: "last" }}
        className="content"
      >
        {children}
        <div className="up-wrap">
          <a
            className="up align-items-center justify-content-center"
            onClick={() => scrollTo("#top")}
            style={{ display: show ? "flex" : "none" }}
          >
            <span>Up</span>
          </a>
        </div>
      </Col>
    </>
  )
}

export default Sidebar
