import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Sidebar from "../../components/sidebar"
import { Col, Row } from "react-bootstrap"

const WorkPage = () => (
  <Layout>
    <SEO title="Work" />
    <Sidebar>
      <Row className="px-2 pb-4">
        <Col className="video-container work">
          <iframe
            src="https://player.vimeo.com/video/452947765?autoplay=1&loop=1&background=1"
            frameborder="0"
            allow="autoplay; fullscreen"
            title="youtube"
            allowfullscreen
          ></iframe>
        </Col>
      </Row>
    </Sidebar>
  </Layout>
)

export default WorkPage
